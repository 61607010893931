import Utility from "./class/utility";
import CUSTOMIZE from "./extended_class/customize";
import CASE from "./module/case";
import HOME from "./module/home";
import PRODUCTS from "./module/products";

// ユーティリティクラスのインスタンス化
const UTIL = new Utility();

// テンプレートインスタンス化（promise登録）
const TPL = new CUSTOMIZE();

// URLによる関数の実行
UTIL.loader( (request, path) => {
  switch( path ){
    case '/': HOME(); break
    case '/case/': CASE(); break
    case '/products/': PRODUCTS(); break
  }
});

// テンプレートインスタンスの初期化（イベント発火）
TPL.init();