import Swiper from "swiper";
import DATE_COUNTER from "../class/date-counter";

const [d] = [document];

const domReady = TPL => {
  new DATE_COUNTER;

  new Swiper('[data-plugin="swiper"]', {
    grabCursor: true,
    slidesPerView: 1,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination'
    },
    on: {
      slideChangeTransitionEnd (swiper) {
        const $container = swiper.$el[0].parentNode;
        if( $container ) $container.dataset.sliderIndex = swiper.realIndex;
      }
    }
  });

  
};

export default function PRODUCTS () {
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}