import DATE_COUNTER from "../class/date-counter";
import GET_ARTICLES from "../class/getArticles";
import { caseBlock } from "../class/case-block";
import FirstViewEndObserver from "../extended_class/FirstViewEndObserver";

const [w, d, header, mq] = [window,document,document.querySelector('.rwd002-header'),window.matchMedia( "(max-width: 900px)" ) ];

class FirstViewEndObserverHome extends FirstViewEndObserver {
  constructor (opt) { super(opt) }

  intersectIn () {
    header.classList.add('is-home-first-view');
  }
  intersectOut () {
    header.classList.remove('is-home-first-view');
  }
}

class Blocks {
  constructor() {
    this.headline();
    this.news();
  }

  //headline
  headline() {
    const getArticle = new GET_ARTICLES('.block-headline__article[data-plugin="getArticles"]');
    getArticle.makeItem = (item) => `
      <a href="${item.href}" class="block-headline__link">
        <h3 class="subject">${item.title.str_truncate(30)}</h3>
      </a>`;
    getArticle.render();
  }

  news () {
    //NEWS
    const getArticle = new GET_ARTICLES('.block-news__list[data-plugin="getArticles"]');
    getArticle.makeItem = (item,content) => `
    <li class="block-${content}-item">
      <a href="/${content}/?mode=detail&amp;article=${item.id}" class="block-${content}-item__link">
        <div class="block-${content}-item__body">
          <div class="date-cate">
            <span class="date">${item.date}</span>
            <span class="cate">${item.category.name}</span>
          </div>
          <h4 class="title ${item.is_new? 'is-new':''}">${item.title.str_truncate(24)}</h4>
        </div>
      </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);
    getArticle.render();
  }

}

const domReady = TPL => {
  header.classList.add('is-home-first-view');
  new FirstViewEndObserverHome;
  new Blocks;
  new DATE_COUNTER;
  new caseBlock;
};

const jqInit = () => {
  
  w.jQuery = $

  /****************************
   * movie background
   */
  $.when(
    $.getScript('/assets/js/vendor/jquery.mb.YTPlayer.min.js')
  ).then( () => {
    const $MV = $('[data-plugin="ytplayer"]')
    const param = id => `{
      videoURL: '${id}',
      containment: '.mainvisual__inner',
      mute: true,
      showControls: false,
      autoPlay: true,
      useOnMobile: true,
      showYTLogo: false,
      optimizeDisplay: true,
      abundance: '${(mq.matches)? 0.005: 0.11}',
      stopMovieOnBlur: false,
      loop: true,
      startAt: 0,
      anchor: 'top,center'
    }`.replace(/\n|\s/g,'')
    $MV.attr('data-property',param( mq.matches? 'jfjdueTeA-A': 'fsQI47JmoC0'));
    $MV.YTPlayer({
      onReady: function() {
        $('.mainvisual__inner').addClass('is-loaded');
      }
    });
    $MV.YTPPlay();
  });
};

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}